 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable, runInAction } from "mobx";



import { $framework } from "@opendash/core";
import { $parse } from "@opendash/plugin-parse";
import Parse from "parse";
import { Chat, Message } from "../parse";

export class ChatService {
  

   __init() {this.chats = []}
   __init2() {this.messages = []}
   __init3() {this.seenChats = {}}

  constructor(kommuter) {;ChatService.prototype.__init.call(this);ChatService.prototype.__init2.call(this);ChatService.prototype.__init3.call(this);
    this.kommuter = kommuter;

    makeAutoObservable(this);

    if (Parse.User.current()) {
    }
  }

  get count() {
    const chats = this.chats.filter((chat) => !this.isChatSeen(chat));

    return chats.length;
  }

   async onUser(user) {
    const online = this.kommuter.network.connected;

    // Load chats

    const chatQuery = new Parse.Query(Chat)
      // TODO: Not working
      .descending("lastMessageAt")
      .include("offer")
      .include("requestUser")
      .include("offerUser")
      .include("requestUserProfile")
      .include("offerUserProfile")
      .include("start")
      .include("end")
      .limit(1000000);

    if (!online) {
      chatQuery.fromLocalDatastore();
    }

    await this.updateChats(chatQuery);

    if (online) {
      const chatSubscription = await chatQuery.subscribe();

      chatSubscription.on("open", () => {
        console.info("chatSubscription opened");
      });

      chatSubscription.on("create", (object) => {
        console.info("chat created");
        this.updateChats(chatQuery);
      });

      chatSubscription.on("update", (object) => {
        console.info("chat updated");
        this.updateChats(chatQuery);
      });

      chatSubscription.on("enter", (object) => {
        console.info("chat entered");
        this.updateChats(chatQuery);
      });

      chatSubscription.on("leave", (object) => {
        console.info("chat left");
        this.updateChats(chatQuery);
      });

      chatSubscription.on("delete", (object) => {
        console.info("chat deleted");
        this.updateChats(chatQuery);
      });

      chatSubscription.on("close", () => {
        console.info("chatSubscription closed");
      });
    }

    // Load messages

    const messageQuery = new Parse.Query(Message)
      .ascending("createdAt")
      .limit(1000000);

    if (!online) {
      messageQuery.fromLocalDatastore();
    }

    await this.updateMessages(messageQuery);

    if (online) {
      const messagesSubscription = await messageQuery.subscribe();

      messagesSubscription.on("open", () => {
        console.info("messagesSubscription opened");
      });

      messagesSubscription.on("create", (object) => {
        console.info("messages created");
        this.updateMessages(messageQuery);
      });

      messagesSubscription.on("update", (object) => {
        console.info("messages updated");
        this.updateMessages(messageQuery);
      });

      messagesSubscription.on("enter", (object) => {
        console.info("messages entered");
        this.updateMessages(messageQuery);
      });

      messagesSubscription.on("leave", (object) => {
        console.info("messages left");
        this.updateMessages(messageQuery);
      });

      messagesSubscription.on("delete", (object) => {
        console.info("messages deleted");
        this.updateMessages(messageQuery);
      });

      messagesSubscription.on("close", () => {
        console.info("messagesSubscription closed");
      });
    }

    // seen chats

    const seenChats = await $framework.services.UserStorageService.get(
      "kommuter:chats-seen"
    );

    runInAction(() => {
      this.seenChats = (seenChats ) || {};
    });

    console.log("seenChats", this.seenChats);
  }

   markChatAsSeen(chatId) {
    this.seenChats[chatId] = Date.now();

    $framework.services.UserStorageService.set(
      "kommuter:chats-seen",
      this.seenChats
    ).catch((error) => console.error(error));
  }

   isChatSeen(chat) {
    const id = chat.id;
    const lastMessageAt = _optionalChain([chat, 'access', _ => _.get, 'call', _2 => _2("lastMessageAt"), 'optionalAccess', _3 => _3.valueOf, 'call', _4 => _4()]) || 0;
    const lastMessageUserId = _optionalChain([chat, 'access', _5 => _5.get, 'call', _6 => _6("lastMessageUser"), 'optionalAccess', _7 => _7.id]);

    if (lastMessageUserId === $parse.user.id()) {
      return true;
    }

    return !!this.seenChats[id] && this.seenChats[id] > lastMessageAt;
  }

   async updateChats(query) {
    const chats = await query.find();

    if (this.kommuter.network.connected) {
      await Parse.Object.pinAll(chats);
    }

    runInAction(() => {
      this.chats = chats;
    });
  }

   async updateMessages(messageQuery) {
    const messages = await messageQuery.find();

    if (this.kommuter.network.connected) {
      await Parse.Object.pinAll(messages);
    }

    runInAction(() => {
      this.messages = messages;
    });
  }
}
